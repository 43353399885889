<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link4')}}</h2>
        <div class="text-red bold Truculenta text-37" style="max-width:850px;margin:auto;" v-html="Lang.get('text4')"></div>
        <div>

        </div>
        <div v-if="Lang.SelectedLanguage=='tr'" class="mt-5">
            <img src="../assets/map-big.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='en'" class="mt-5">
            <img src="../assets/map-bigEN.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">

            <div style="max-width:550px;margin:auto;" class="mt-5">
                <center>

                    <p>
                        Yıldız Holding’in atıştırmalık alanındaki Ülker, McVitie’s, Godiva gibi ikononik markalarını tek bir çatı altında topladığı şirketi pladis, “Her lokmada tüm dünyaya mutluluk vaat ediyoruz” mottosuyla çıktığı global yolculuğuna başarıyla devam etmektedir.


                    </p>
                    <p>
                        pladis dünyanın farklı coğrafyalarında, farklı tüketici beklenti ve ihtiyaçlarını karşılayacak zenginlikte ürün yelpazesi ile tüm tüketicilerine her lokmada mutluluk götürmek hedefiyle hareket etmektedir. 11 ülkedeki 25 fabrikası, 16 binden fazla çalışanı ve 300 yılı aşkın uzmanlığıyla, sorumlu bir gıda şirketi olarak, en kaliteli ürünleri, dünya çapında potansiyel 4 milyar kişiye ulaştırmak için büyük bir mutlulukla çalışmaktadır.

                    </p>
                </center>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:550px;margin:auto;" class="mt-5">
                <center>

                    <p>
                        Bringing together Yıldız Holding’s core confectionery businesses and beloved brands, including McVitie’s, Ülker, and Godiva under a single roof, pladis continues its global journey successfully which started with the motto “We are promising to bring happiness to the world with every bite.”
                    </p>
                    <p>
                        pladis delivers supermarket series Godiva chocolates, regional brands such as Jacob’s, go ahead!, Carr’s, Flipz, BN, Verkade , MENA market leader Ülker and many more, delighting consumers with products across the snacking category including sweet and savory biscuits, wafer, cake, chocolate, gum and candy to the consumers.
                    </p>
                    <p>
                        Formed in 2016 with a promise to bring happiness to the various geographical parts of the world, pladis is the proud steward of more than 300 years’ baking and confectionery heritage. Equipped with the expertise of more than 16 thousand employees at 25 factories in 11 countries, pladis is happily working to deliver the highest quality products to 4 billion people across the world.

                    </p>

                </center>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>

        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Pladis Hakkında.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/About Pladis.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
